<template>
  <div class="aiz-user-panel order-page" v-if="userData !== undefined">
    
    <div class="aiz-titlebar mt-2 mb-2">
      <div class="row align-items-center">
        <div class="col-md-6">
            <h1 class="h3">{{ $t('ding-dan-xiang-qing') }}</h1>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-4">
        <div class="order-detail">
          <div class="info">
            <img :src="orderInfo.Goods.ImgUrl" alt="">
            <div class="title">{{orderInfo.Goods.GoodsName}}</div>
            <div class="price">${{orderInfo.Price}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md-4">
        <div class="order-detail">
          <div class="page-title mb-2">{{ $t('ding-dan-ming-xi') }}</div>
          <div class="detail-box">

            <div class="info-line">
              <span>{{ $t('gou-mai-jine') }}</span>
              <span>${{parseFloat(orderInfo.Price).toFixed(2)}}</span>
            </div>
            <div class="info-line">
              <span>{{ $t('gou-mai-shou-yi') }}</span>
              <span>${{parseFloat(orderInfo.EarnCommission).toFixed(2)}}</span>
            </div>
            <div class="info-line" v-if="orderInfo.BurstOrderID > 0">
              <span>{{ $t('Complete the order') }}:</span>
              <span>${{parseFloat(userData.UserAccount.Balance - orderInfo.Price).toFixed(2)}}</span>
            </div>
            <div class="address-box">
              <div class="title">
                <img :src="addressUrl" alt="">
                <span>{{ $t('wu-liu-di-zhi-xin-xi') }}</span>
              </div>
              <div class="address">
                <div>{{orderInfo.ShippingAddress.Address}}</div>
                <div>{{orderInfo.ShippingAddress.ZipCode}} {{orderInfo.ShippingAddress.City}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row " v-if="!hideBtn">
      <div class="col-md-4">
        <button type="button" class="order-btn btn btn-primary" @click="submit">{{ $t('que-ren-gou-mai-shang-pin') }}</button>
      </div>
    </div>

    
		<Popup v-model="showPopup" class="msg-popup" :close-on-click-overlay="false">
      <div class="msg-box">
        <div class="content flex-column-center clear">
          <div class="logo" >
            <img :src="loadingUrl" alt="">
          </div>
          <div class="msg flex-center">
						<span>{{msg}}</span>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import { taskPay,userInfo } from '@/api/user'
import pageBar from '@/components/page'
import {Popup} from 'vant'
import { resetPrice } from '@/utils/common'
export default {
  components: {
    pageBar,
    Popup
  },
  data() {
    return {
      goodsUrl: require('../../assets/imgs/goods1.jpg'),
      addressUrl: require('../../assets/imgs/icon-address.png'),
      loadingUrl: require('../../assets/imgs/car.gif'),
      showPopup: false,
      orderInfo: {
				Goods: {},
				ShippingAddress: {},
			},
      userData:undefined,
      msg: '',
      hideBtn: false
    }
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  watch: {},
  created() {
    this.getuserInfo()
  },
  mounted() {
    let info = sessionStorage.getItem('taskDetail')
    if (info) {
      this.orderInfo = JSON.parse(info)
      this.orderInfo.ShippingAddress.City = this.resetUserInfo(this.orderInfo.ShippingAddress.City)
      this.orderInfo.ShippingAddress.ZipCode = this.resetUserInfo(this.orderInfo.ShippingAddress.ZipCode)
      this.orderInfo.ShippingAddress.Address = this.resetUserInfo(this.orderInfo.ShippingAddress.Address)
      if (this.orderInfo.Status == 1) {
        this.hideBtn = true
      }
    }
    console.log(111, this.orderInfo)
  },
  methods: {
    getuserInfo(){
      userInfo().then(res => {
        this.userData = res.data
      }).catch(e => {
        this.$toast.fail(this.$t('xi-tong-yi-chang'))
      })
    },
    resetPrice,
    resetUserInfo(info) {
			if (!info) {
				return ''
			}
      let newInfo = ''
      if (info.length <= 4) {
        newInfo = new Array(info.length).join('*') + info.substring(info.length - 1)
      } else {
        newInfo = info.substring(0, 2) + new Array(info.length - 3).join('*') + info.substring(info.length - 2)
      }
      return newInfo
    },
    submit() {
			let param = {
				ID: this.orderInfo.ID
			}
			this.showPopup = true
			this.msg = this.$t('zheng-zai-huo-qu-ding-dan-shu-ju')
			taskPay(param).then(res => {
				if (res.code == 0) {
					setTimeout(() => {
						this.msg = this.$t('zheng-zai-lian-jie-gou-mai-ding-dan')
						setTimeout(() => {
							this.msg = this.$t('zheng-zai-jie-suan-ding-dan-jin-e')
							setTimeout(() => {
								this.msg = this.$t('zheng-zai-huo-qu-shou-huo-di-zhi')
								setTimeout(() => {
									this.msg = this.$t('shang-pin-ding-dan-gou-mai-cheng-gong')
									setTimeout(() => {
										this.showPopup = false
										this.back()
									}, 1250)
								}, 1250)
							}, 1250)
						}, 1250)
					}, 500)
				} else {
          setTimeout(() => {
            this.msg = this.$t('zheng-zai-lian-jie-gou-mai-ding-dan')
            setTimeout(() => {
              this.msg = this.$t('zheng-zai-jie-suan-ding-dan-jin-e')
              setTimeout(() => {
                this.msg = this.$t('zheng-zai-huo-qu-shou-huo-di-zhi')
                this.$toast.fail(res.msg)
                this.showPopup = false
              }, 1250)
            }, 1250)
          }, 500)
				}
			}).catch(e => {
				this.$toast.fail(this.$t('xi-tong-yi-chang'))
				this.showPopup = false
			})
		},
    cancel() {
			this.showPopup = false
		},
    back() {
      this.$router.go(-1)
    },
    toBuy() {
      this.showPopup = true
      this.msg = this.$t('zheng-zai-huo-qu-ding-dan-shu-ju-0')
    }
  }
}
</script>